import { Card, MainLayout, Section } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'
import { Icon, Stack } from '@fluentui/react'
import { memo } from 'react'
import useIsMobile from '@/shared/hooks/useIsMobile'
import MoreLoading from '@/features/more/components/MoreLoading'
import useExtApps from '@/features/more/hooks/useExtApps'
import ExtAppFetchingError from '@/features/more/components/ExtAppFetchingError'
import ExtAppLinkGroup from '@/features/more/components/ExtAppLinkGroup'

const More = () => {
  const isMobile = useIsMobile('xs')
  const [linkGroups, { isLoading, hasLinks, isSuccess, isError, refetch }] = useExtApps()

  return (
    <MainLayout>
      <PageHeader className="more" label='More' />
      <Section className='c-more' titleId='moreExternal' title='External Applications'>
        <Card ariaLabelledBy='moreExternal'>
          <Stack tokens={{ childrenGap: isMobile ? 16 : 0 }} className="c-more__links">
            {isLoading && <MoreLoading />}
            {isSuccess && !isLoading && !isError ? (
              linkGroups.map(([groupKey, links], i) => {
                if (!links.length) return null
                return <ExtAppLinkGroup key={groupKey} groupKey={groupKey} links={links} />
              })
            ) : null}
            {!hasLinks && !isLoading && !isError ? (
              <p className='c-more__noaccess'>
                <Icon iconName='Info' className='c-more__noaccess-icon'/> 
                <span>You do not have access to any external applications at this time.</span>
              </p>
            ) : null}
            {isError && <ExtAppFetchingError refetch={refetch} />}
          </Stack>
        </Card>
      </Section>
    </MainLayout>
  )
}

export default memo(More)