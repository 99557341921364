import DonutChart from '@/lib/common/components/charts/donut-chart/DonutChart'
import { getOrCreateTooltip } from '@/lib/common/components/charts/utils/ChartJS.helper'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import UtilService from '@/lib/common/services/util/UtilService'
import { IPortfolioSettings, SLEEVE_DISPLAY_TYPE, exportGridData, roundMarketValueWidgetValue, useGetPortfolioAllocation, useGetPortfolioSettingsByIdQuery, useGetSleevesFilterQuery } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GridApi, ProcessCellForExportParams, RowClickedEvent } from 'ag-grid-community'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SkeletonClientPortfolioAllocation from '../Skeletons/SkeletonClientPortfolioAllocation'
import ReturnToTotalPortfolioButton from '../TotalPortfolioButton/ReturnToTotalPortfolio'
import AllocationGrid from './AllocationGrid/AllocationGrid'
import AllocationMobile from './AllocationMobile/AllocationMobile'
import { ArcElement, Chart } from 'chart.js'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import { PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS } from '../../Components/Grid/Column/PerformanceSleeveColumn'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'

import './Allocation.scss'

export interface AllocationProps {
  dashboardSettings: DashboardSettings,
  portfolioSettings: IPortfolioSettings,
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string,
  previewAccountId?: string,
  setShowTotalPortfolioButton?: (showTotalPortfolioButton: boolean) => void,
  setTotalPortfolioId?: (totalPortfolioId: string) => void,
}

export default function Allocation({
  dashboardSettings,
  portfolioSettings,
  portfolioId,
  asOfDate,
  sleeveId,
  previewAccountId,
  setShowTotalPortfolioButton,
  setTotalPortfolioId,
}: AllocationProps) {
  const isMobile = useIsMobile('sm')
  const history = useHistory()
  const { data, isFetching, isError, refetch } = useGetPortfolioAllocation({ portfolioId, asOfDate, previewAccountId })
  const { data: sleevesFilterData } = useGetSleevesFilterQuery({ portfolioId, asOfDate, accountId: previewAccountId })
  const sleevesFilterMap = (sleevesFilterData || []).reduce((acc, value) => {
    acc[value.id] = value
    return acc
  }, {})
  const allocationSummarySettings = portfolioSettings?.allocationSummary
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const [gridApi, setGridApi] = useState(null as GridApi)
  const [allocationIndex, setAllocationIndex] = useState(-1)
  const donutChartRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleDocumentMouseMove)
    }
  }, [])

  if (isError) {
    return (
      <Card className='c-onboarding-welcome__right__card card-right' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Allocation' subtitle={subtitle} >
          <GeneralError title='Failed to load Portfolio Allocation' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  let totalAssets
  let allocationTemplateGrid = []
  const parsedSleeveId = !!sleeveId ? Number(sleeveId) : 0
  const result = (data || [])
  const firstOption = result.find(p => {

    if (p.Lvl === 0) {
      totalAssets = p
    }

    if (parsedSleeveId) {
      return (p.PLISk === parsedSleeveId) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    }

    return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })

  let options = [
    firstOption,
    ...result.filter(p => {
      return (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE) && (p.ParentPLISk === firstOption?.PLISk)
    })
  ].filter(option => !!option)

  if (allocationSummarySettings) {
    // removes data that is not allowed from Vermilion settings
    options = options.filter(o => {
      const removeData = ((o.Lvl == 1) && (!allocationSummarySettings.displayLevel1))
        || ((o.Lvl === 2) && (!allocationSummarySettings.displayLevel2))
        || ((o.Lvl === 3) && (!allocationSummarySettings.displayLevel3))
        || ((o.Lvl === 4) && (!allocationSummarySettings.displayLevel4))
        || ((o.Lvl === 5) && (!allocationSummarySettings.displayLevel5))
        || (o.Lvl > 5)
      return !removeData
    })
  }

  allocationTemplateGrid = options.map((row, i) => {
    return {
      ...row,
      name: row.Name,
      value: row?.MV,
      total: row?.MV,
      color: row?.Color,
      currentMktValue: row?.MV,
      actualAllocation: row?.Alloc,
      longTrmTrgt: row?.TgtAllocLT,
      longTrmTrgtVariance: row?.TgtVarPct,
      currencySymbol: row?.CurrencySymbol,
      level: row?.Lvl,
      sleeveId: row?.id,
    }
  })

  const min = Math.min(...allocationTemplateGrid.map(alt => alt.level))
  const allocationTemplateChart = allocationTemplateGrid.filter(row => {
    return row.level > min && row.value > 0
  })

  const createTableElement = (elementType, styles = {}) => {
    const element = document.createElement(elementType)
    Object.assign(element.style, styles)
    return element
  }

  const appendTextToElement = (element, text) => {
    const textNode = document.createTextNode(text)
    element.appendChild(textNode)
  }

  const externalTooltipHandler = (chart, tooltip, series) => {
    const tooltipEl = getOrCreateTooltip(chart, tooltip)
    tooltipEl.style.width = '256px'

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map(b => b.lines) as string[][]
      const listInfo = series.filter(s => s.name === titleLines[0])
      const currentSleeveId = `${listInfo && listInfo[0] && listInfo[0].id}`
      const tableHead = titleLines.reduce((thead, title) => {
        const tr = createTableElement('tr', { borderWidth: '0' })
        const th = createTableElement('th', { borderWidth: '0' })
        appendTextToElement(th, title)
        tr.appendChild(th)
        thead.appendChild(tr)
        return thead
      }, createTableElement('thead'))

      const tableBody = bodyLines.reduce((tbody, body, i) => {
        const tr1 = createTableElement('tr', { backgroundColor: 'inherit', borderWidth: '0' })
        const tr2 = createTableElement('tr', { backgroundColor: 'inherit', borderWidth: '0' })
        const tr3 = createTableElement('tr', { backgroundColor: 'inherit', borderWidth: '0' })
        const tdElements = ['td', 'td2'].map(() => createTableElement('td', { borderWidth: '0', textAlign: 'start', marginRight: '5px' }))
        const tdSecondElements = ['tdSecond', 'tdSecond2'].map(() => createTableElement('td', { borderWidth: '0', textAlign: 'start' }))
        const tdThird = createTableElement('td', { borderWidth: '0', textAlign: 'start', width: '150px' })
        tdThird.setAttribute('colspan', '2')
        const formatNegative = (value: string) => {
          try {
            // market value round and no decimal places
            const marketValueNumber = roundMarketValueWidgetValue(Number(`${value || ''}`.replace(/\,/g, '')))
            const formattedValue = marketValueNumber.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })

            if (formattedValue.includes('-')) {
              return `(${formattedValue.replace('-', '')})`
            }

            return formattedValue
          } catch (error) {
            return value
          }
        }
        const textNodes = [
          ['Current Market Value:', 'Actual Allocation (%):'],
          [`${listInfo[0]['currencySymbol']}${formatNegative(body[0])}`, `${listInfo[0]['actualAllocation'].toFixed(1)}`]
        ]

        textNodes.forEach(([label, value], index) => {
          appendTextToElement(tdElements[index], label)
          appendTextToElement(tdSecondElements[index], value)

          tr1.appendChild(tdElements[index])
          tr2.appendChild(tdSecondElements[index])
        })
        tdThird.appendChild(document.createTextNode(`Click to see ${listInfo[0]['name']}'s Data`))
        tr3.appendChild(tdThird)

        tbody.appendChild(tr1)
        tbody.appendChild(tr2)

        // does not show click to select on current selected sleeve or disabled sleeve
        if ((sleeveId !== currentSleeveId) && (!sleevesFilterMap[currentSleeveId] || !sleevesFilterMap[currentSleeveId].disabled)) {
          tbody.appendChild(tr3)
        }

        return tbody
      }, createTableElement('tbody'))

      const tableRoot = tooltipEl.querySelector('table')
      tableRoot.style.width = '100%'

      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      tableRoot.appendChild(tableHead)
      tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    Object.assign(tooltipEl.style, {
      opacity: '1',
      left: `${positionX + tooltip.caretX}px`,
      top: `${positionY + tooltip.caretY}px`,
      padding: `${tooltip.options.padding}px ${tooltip.options.padding}px`
    })
  }

  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Allocation',
      fileName: `allocation-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback:  (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { originalCellClass } = colDef as any
        const { cellClass } = colDef
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && cellClass) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT)
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT)
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              return value?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          }
          else if (hasCellClass(className, PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS)) {
            const { StatusAggregate, footNoteDataFlags  } = data || {}
            let dataFlags = ''
    
            if (StatusAggregate) {
              if (StatusAggregate === 'Preliminary') {
                dataFlags += ' * '
              } else if (StatusAggregate === 'PROXY') {
                dataFlags += ' † '
              }
            }
            
            if (footNoteDataFlags && footNoteDataFlags.length) {
              dataFlags += ' '  + footNoteDataFlags.join(' ')
            }

            return value + dataFlags
          }
        }
        return value
      }
    })
  }

  const disableAllocationGridComponentsSelectedState = () => {
    try {
      setAllocationIndex(-1)

      if (donutChartRef && donutChartRef.current) {
        const chartInstance = donutChartRef.current as Chart

        if (chartInstance) {
          const tooltip = chartInstance.tooltip

          if (tooltip) {
            const chartTooltip = tooltip as any as {
              update: (update: boolean) => void
            }
            tooltip.setActiveElements([], {
              x: 0,
              y: 0,
            })
            chartTooltip.update(true)
            chartInstance.draw()
          }
        }
      }

      const allocationGridSelectedRows = document.querySelectorAll('.AllocationGrid .ag-row-hover')

      if (allocationGridSelectedRows && allocationGridSelectedRows.length) {
        for (let i = 0; i < allocationGridSelectedRows.length; i++) {
          const allocationGridSelectedRow = allocationGridSelectedRows.item(i) as HTMLElement
          if (allocationGridSelectedRow) {
            if (allocationGridSelectedRow.classList) {
              allocationGridSelectedRow.classList.remove('ag-row-hover')
            }
          }
        }
      }
    } catch (error) {
      console.error('Error while hiding allocation chart tooltip.', error)
    }
  }

  const handleDocumentMouseMove = (event: MouseEvent) => {
    const allocationGridElement = document.querySelector('.AllocationGrid') as HTMLElement
    const allocationChartElement = document.querySelector('.c-portfolio-section-allocation .DonutChartContainer') as HTMLElement
    
    if (!allocationGridElement || !allocationChartElement) {
      return
    }
    
    const isAllocationComponentElement = (targetElement: HTMLElement, allocationComponentElement: HTMLElement) => {
      let currentElement = targetElement

      while (currentElement) {
        if (currentElement === allocationComponentElement) {
          return true
        }

        currentElement = currentElement.parentElement
      }

      return false
    }

    if (event && event.target) {
      const targetElement = event.target as HTMLElement

      if (!isAllocationComponentElement(targetElement, allocationGridElement) && !isAllocationComponentElement(targetElement, allocationChartElement)) {
        disableAllocationGridComponentsSelectedState()
      }
    }
  }

  const handleGridRowMouseLeave = (event: CellMouseOutEvent) => {
    disableAllocationGridComponentsSelectedState()
  }
 
  const handleGridRowHover = (event: CellMouseOverEvent) => {
    try {
      const row = event.data
      const seriesItem = allocationTemplateChart.find(item => item.name === row.name)

      if (seriesItem && donutChartRef && donutChartRef.current) {
        const chartInstance = donutChartRef.current as Chart

        if (chartInstance) {
          const tooltip = chartInstance.tooltip

          if (tooltip) {
            const arcElement = chartInstance.getDatasetMeta(0).data[allocationTemplateChart.indexOf(seriesItem)] as ArcElement

            if (arcElement) {
              const activeElement = (arcElement as any).$context
              const chartTooltip = tooltip as any as {
                update: (update: boolean) => void
              }
              tooltip.setActiveElements([activeElement], {
                x: arcElement.x,
                y: arcElement.y,
              })
              chartTooltip.update(true)
              chartInstance.draw()
            }
          }
        }
      }
    } catch (error) {
      console.error('Error while triggering allocation chart tooltip.', error)
    }
  }

  const redirectToManager = (sleeveId) => {
    if (sleeveId) {
      if (previewAccountId) {
        history.push(`/preview/dashboard/${previewAccountId}/portfolio/${portfolioId}/${asOfDate}/${sleeveId}`)
        return
      }

      history.push(`/portfolio/${portfolioId}/${asOfDate}/${sleeveId}`)
    }
  }

  const handleDonutChartClick = (seriesItem) => {
    const { sleeveId } = seriesItem || {} as any
    redirectToManager(sleeveId)
  }

  const handleDonutChartBlur = () => {
    setAllocationIndex(-1)
  }

  const handleGridRowClick = (event: RowClickedEvent) => {
    const { sleeveId } = event.data || {} as any
    redirectToManager(sleeveId)
  }

  const showTotalAssetsButton = () => {
    const { id } = totalAssets || {} as any

    setShowTotalPortfolioButton(false)

    if (sleeveId && totalAssets?.id != sleeveId) {
      setShowTotalPortfolioButton(true)
      setTotalPortfolioId(id)

      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={id} previewAccountId={previewAccountId} />
        </div>
      )
    }

    return null
  }

  return (
    <>
      {isMobile ? (
        <AllocationMobile setShowTotalPortfolioButton={setShowTotalPortfolioButton} setTotalPortfolioId={setTotalPortfolioId}
          dashboardSettings={dashboardSettings} portfolioId={portfolioId} asOfDate={asOfDate} portfolioSettings={portfolioSettings} sleeveId={sleeveId}
          previewAccountId={previewAccountId} />
      ) : (
        <Card className='c-onboarding-welcome__right__card card-right' ariaLabel='Welcome'>
          <Section className='c-portfolio-section' title='Allocation' subtitle={subtitle} actions={[
            <div className='button-container' key={0}>
              {showTotalAssetsButton()}
              <div key='downloadBtn' className='download-button-container'>
                <DefaultButton className='download-button' onClick={handleExcelDownloadClick}>
                  <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
                </DefaultButton>
              </div>
            </div>
          ]}>
            {
              isFetching ? <SkeletonClientPortfolioAllocation /> : (
                <div className='c-portfolio-section-allocation'>
                  <DonutChart
                    ref={donutChartRef}
                    selectedItem={allocationTemplateChart[allocationIndex]}
                    series={allocationTemplateChart}
                    legend={{
                      position: 'right'
                    }}
                    responsive={true}
                    centerTextOptions={{
                      showCenterText: true,
                      getCenterText: () => {
                        const maxValue = allocationTemplateGrid?.[0]?.total
                        const currencySymbol = allocationTemplateGrid?.[0]?.currencySymbol || '$'
                        return ({
                          top: '105px',
                          text: currencySymbol + `${UtilService.nFormatter(maxValue, 1)}`,
                          font: {
                            size: '30px',
                            family: 'Segoe UI'
                          }
                        })
                      }
                    }}
                    customTooltipHandler={externalTooltipHandler}
                    onSeriesClick={handleDonutChartClick}
                    onSeriesLeave={handleDonutChartBlur}
                  />
                  <AllocationGrid
                    rows={allocationTemplateGrid}
                    portfolioSettings={portfolioSettings}
                    sleevesFilterMap={sleevesFilterMap}
                    sleeveId={sleeveId}
                    sendGridApi={setGridApi}
                    onMouseLeave={handleGridRowMouseLeave}
                    onRowHover={handleGridRowHover}
                    onAllocationIndexUpdate={setAllocationIndex}
                    onRowClick={handleGridRowClick}
                  />
                  
                </div>
              )
            }
          </Section>
        </Card>
      )}
    </>
  )
}