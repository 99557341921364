import { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import MainLayout from '@/shared/components/Layout/MainLayout'
import MonthEndSelector from '@/lib/common/components/date/monthend/MonthEndSelector'
import { formatDate, getMaxAsOfDate, getMinAsOfDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { useGetDashboardSettings, useGetPreviewDashboardSettingsByAccountId } from '@/shared/api/services/legacyService'
import { getPortfolioName, isAsOfDateValidForQuarterlyReportingPeriodicity, isQuarterlyReportingPeriodicity, useGetPortfolioQuery, useGetPortfolioSettingsByIdQuery } from '@/shared/api/services/portfolioService'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import {
  FluentProvider,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  makeStyles,
  shorthands,
  webLightTheme
} from '@fluentui/react-components'
import { Link } from 'react-router-dom'
import ClientPortfolioHome from './ClientPortfolioHome'
import SleeveDropdown from './ClientPortfolioHome/SleeveDropdown/SleeveDropdown'
import ClientPortfolioPerformance from './ClientPortfolioPerformance'
import ClientPortfolioTrade from './ClientPortfolioTrade'
import ClientPortfolioDisclosures from './ClientPortfolioDisclosures/ClientPortfolioDisclosures'
import { isPortfolioDashboardSettingsEnabled } from '@/features/client/components/ClientSettings/ClientSettings.model'

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...shorthands.padding('50px', '20px'),
    rowGap: '20px',
  },
})

export interface ClientPortfolioProps extends RouteComponentProps<{
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string
}> {
  previewAccountId?: string // Add the new optional prop here
}

export default function ClientPortfolio({ match, previewAccountId } : ClientPortfolioProps) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>()
  const history = useHistory()
  const { portfolioId, asOfDate, sleeveId } = match.params
  const styles = useStyles()  
  const { data: portfolio } = useGetPortfolioQuery({ portfolioId, accountId: previewAccountId })
  const { data: baseDashboardSettings } = useGetDashboardSettings()
  const { data: previewDashboardSettings } = useGetPreviewDashboardSettingsByAccountId(previewAccountId)
  const dashboardSettings = previewAccountId ? previewDashboardSettings : baseDashboardSettings
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery({ portfolioId, accountId: previewAccountId })
  const [seletectedTab, setSelectedTab] = useQueryParamSubscription<string>('tab', 'portfolioHome')
  const isPortfolioDashboardEnabled = isPortfolioDashboardSettingsEnabled(dashboardSettings)
  const isQuarterlyReportingPortfolio = isQuarterlyReportingPeriodicity(portfolio)
  const calendarType = isQuarterlyReportingPortfolio ? 'QUARTERLY' : 'MONTHLY'

  const getDashboardPath = () => {
    if (previewAccountId) {
      return `/preview/dashboard/${previewAccountId}`
    }

    return '/'
  }

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(`${data.value}`)
  }
  
  const onSleeveChange = (sleeveId: string) => {
    if (previewAccountId) {
      history.push(`/preview/dashboard/${previewAccountId}/portfolio/${portfolioId}/${asOfDate}/${sleeveId}`)
      return
    }
    history.push(`/portfolio/${portfolioId}/${asOfDate}/${sleeveId}`)
  }

  const onDateChange = (date: Date) => {
    if (previewAccountId) {
      history.push(`/preview/dashboard/${previewAccountId}/portfolio/${portfolioId}/${formatDate(date)}${!!sleeveId ? '/' + sleeveId : ''}?tab=${seletectedTab}`)
      return
    }
    history.push(`/portfolio/${portfolioId}/${formatDate(date)}${!!sleeveId ? '/' + sleeveId : ''}?tab=${seletectedTab}`)
  }

  const getPortfolioMinAsOfDate = () => {
    const minAsOfDate = getMinAsOfDate()
    const portfolioInceptionDate = portfolio?.inceptionDate
    const settingsInceptionDate = portfolioSettings?.inceptionDate

    // returns portfolio settings inception date if more recent than system min as of date
    if (settingsInceptionDate) {
      if (settingsInceptionDate > minAsOfDate) {
        return settingsInceptionDate
      }
    }

    // returns portfolio inception date if more recent than system min as of date
    if (portfolioInceptionDate) {
      if (portfolioInceptionDate > minAsOfDate) {
        return portfolioInceptionDate
      }
    }

    // return system min as of date
    return minAsOfDate
  }

  if (!isPortfolioDashboardEnabled) {
    return (
      <MainLayout className='c-client ClientPortfolio' id='client'>
        <span className='c-client-portfolio-breadcrumb'><h1 className='root-breadcrumb'><Link to={getDashboardPath()} className='link-back'> Dashboard</Link> /</h1><h1 className='path-breadcrumb'>{ getPortfolioName(portfolio) }</h1></span>
      </MainLayout>
    )
  }

  // redirects to Dashboard in case of quarterly reporting with selected quarterly intra month date
  if (isQuarterlyReportingPortfolio) {
    if (!isAsOfDateValidForQuarterlyReportingPeriodicity(portfolio, asOfDate)) {
      return <Redirect to={getDashboardPath()} />
    }
  }

  return (
    <MainLayout className='c-client ClientPortfolio' id='client'>
      <span className='c-client-portfolio-breadcrumb'><h1 className='root-breadcrumb'><Link to={getDashboardPath()} className='link-back'> Dashboard /</Link></h1><h1 className='path-breadcrumb'>{ getPortfolioName(portfolio) }</h1></span>
      <div className='c-client-portfolio-body'>
        <FluentProvider theme={webLightTheme}>
          <div className={`${styles.root} c-client-portfolio-tab-parent`} >
            <div className={seletectedTab !== 'performance' ? 'c-client-portfolio-tab-tools' : 'c-client-performance-tab-tools'}>
              <TabList className='tab-main' selectedValue={seletectedTab} onTabSelect={onTabSelect}>
                <Tab value='portfolioHome'>Portfolio Home</Tab>
                { (dashboardSettings && dashboardSettings.enablePortfolioDashboardPerformanceTab) ? (
                  <Tab value='performance'>Performance</Tab>
                ) : null }
                { (dashboardSettings && dashboardSettings.enablePortfolioDashboardTradesTab) ? (
                  <Tab value='trades'>Trades</Tab>
                ) : null }
                { (dashboardSettings && dashboardSettings.enablePortfolioDashboardDisclosuresTab) ? (
                  <Tab value='disclosures'>Disclosures</Tab>
                ) : null }
              </TabList>
              <div className={seletectedTab === 'performance' ? 'filter-container-performance': 'filter-container'}>
                { (seletectedTab === 'portfolioHome') ? (
                  <SleeveDropdown
                    asOfDate={asOfDate}
                    portfolioId={portfolioId}
                    sleeveId={sleeveId}
                    previewAccountId={previewAccountId}
                    onSleeveChange={onSleeveChange}
                  />
                ) : null }
                { (seletectedTab !== 'disclosures') ? (
                  <MonthEndSelector
                    calendarType={calendarType}
                    minDate={getPortfolioMinAsOfDate()}
                    maxDate={getMaxAsOfDate()}
                    value={parseFormattedDate(asOfDate)}
                    onValueChange={onDateChange}
                  />
                ) : null }
              </div>
            </div>
            <div className={`tab-content ${isFullScreen && seletectedTab === 'performance' ? 'tab-content-full-screen' : ''}`}>
              { (seletectedTab === 'portfolioHome') ? (
                <ClientPortfolioHome dashboardSettings={dashboardSettings} portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId}
                  previewAccountId={previewAccountId} />
              ) : null }
              { (dashboardSettings && dashboardSettings.enablePortfolioDashboardPerformanceTab && (seletectedTab === 'performance')) ? (
                <ClientPortfolioPerformance dashboardSettings={dashboardSettings} portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId} setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen}
                  previewAccountId={previewAccountId} />
              ) : null }
              { (dashboardSettings && dashboardSettings.enablePortfolioDashboardTradesTab && (seletectedTab === 'trades')) ? (
                <ClientPortfolioTrade dashboardSettings={dashboardSettings} portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId}
                  previewAccountId={previewAccountId} />
              ) : null}
              {(dashboardSettings && dashboardSettings.enablePortfolioDashboardDisclosuresTab && (seletectedTab === 'disclosures')) ? (
                <ClientPortfolioDisclosures dashboardSettings={dashboardSettings} portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId}
                  previewAccountId={previewAccountId} />
              ) : null}
            </div>
          </div>
        </FluentProvider>        
      </div>
    </MainLayout>
  )
}
