import { mainService } from '.'
import { IMessageUpdateModel, IMessageViewRecord } from '@/shared/types/swagger'

const mainServiceWithMessagesTags = mainService.enhanceEndpoints({ addTagTypes: ['Messages', 'ArchiveMessages'] })
const messageServices = mainServiceWithMessagesTags.injectEndpoints({
  endpoints(build) {
    return {
      getMessagesByRecipientId: build.query<Array<IMessageViewRecord>, number | string>({
        query: (recipientId) => ({ url: `/messages/records/for/${recipientId}`, method: 'GET' }),
        providesTags: ['Messages']
      }),
      createMessage: build.mutation<IMessageViewRecord, Partial<IMessageViewRecord>>({
        query: (data) => {
          return { url: '/messages/record', method: 'POST', data } 
        },
        invalidatesTags: ['ArchiveMessages', 'Messages']
      }),
      updateMessage: build.mutation<IMessageViewRecord, {id: number | string, payload: Partial<IMessageUpdateModel>}>({
        query: ({ id, payload }) => ({ url: `/messages/record/${id}`, method: 'POST', data: payload }),
        invalidatesTags: ['ArchiveMessages', 'Messages']
      }), 
      getArchivedMessagesByRecipientId: build.query<Array<IMessageViewRecord>, number | string>({
        query: (recipientId) => ({ url: `/messages/records/for/${recipientId}/allarchiveddata`, method: 'GET' }),
        providesTags: ['ArchiveMessages']
      }),
      archiveMessageById: build.mutation<boolean, string>({
        query: (id) => ({ url: `/messages/record/${id}/archive`, method: 'PUT', data: { archive: true } }),
        invalidatesTags: ['ArchiveMessages', 'Messages']
      }),
      getMessagesByAccountId: build.query<Array<IMessageViewRecord>, number | string>({
        query: (accountId) => ({ url: `/messages/records/account/${accountId}`, method: 'GET' }),
        providesTags: ['Messages']
      }),
      getArchivedMessagesByAccountId: build.query<Array<IMessageViewRecord>, number | string>({
        query: (accountId) => ({ url: `/messages/records/account/${accountId}/allarchiveddata`, method: 'GET' }),
        providesTags: ['Messages']
      })
    }
  }
})

export const { 
  useGetMessagesByRecipientIdQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useGetArchivedMessagesByRecipientIdQuery,
  useArchiveMessageByIdMutation,
  useGetMessagesByAccountIdQuery,
  useGetArchivedMessagesByAccountIdQuery,
  useLazyGetMessagesByRecipientIdQuery,
} = messageServices