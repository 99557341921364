import { DefaultButton, IIconProps, Modal, Stack, Toggle } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { Radio, RadioGroup } from '@fluentui/react-radio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PanelOptions.scss'

export interface IPanelOptionsProps {
  isExpanded: boolean
  onChangeExpand: (isExpanded: boolean) => void
  isBenchmarkValueAddedFiltered: boolean
  onChangeBenchValueAddedFilter: (isBenchmarkValueAddedFiltered: boolean) => void
  handleDownload: () => void
}

const PanelOptions = ({
  isExpanded,
  isBenchmarkValueAddedFiltered,
  onChangeExpand: onChangeExpand,
  onChangeBenchValueAddedFilter: onChangeBenchValueAddedFilter,
  handleDownload
}: IPanelOptionsProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)
  const cancelIcon: IIconProps = { iconName: 'Cancel' }
  const exportIcon: IIconProps = { iconName: 'FileSymLink' }

  const downloadClick = () => {
    handleDownload()
    hideModal()
  }

  return (
    <div className='PanelOptions'>
      <DefaultButton className='panel-options-button' onClick={showModal}>
        <FontAwesomeIcon className='icons-mb' icon={['fas','ellipsis']} />
      </DefaultButton>
      <Modal
        titleAriaId='Settings'
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        className='ModalSettings'
      >
        <div className='ms-container'>
          <div className='ms-header'>
            <span></span>
            <span className='title-header'>Settings</span>
            <FontAwesomeIcon className='icons-mb' icon={['fas','times']} onClick={hideModal} />
          </div>
          <div className='ms-body'>
            <RadioGroup className='expand-settings' defaultValue={`${isExpanded ? 'expand' : 'collapse'}`} onChange={(e) => onChangeExpand(!isExpanded)}>
              <div className='option'>
                <span className='option-label'>Expand All</span>
                <Radio value='expand' label='' />
              </div>
              <div className='option'>
                <span className='option-label'>Collapse All</span>
                <Radio value='collapse' label='' />
              </div>
            </RadioGroup>
            <div className='option-export'>
              <span className='option-label'>
                <FontAwesomeIcon className='icons-mb-file-export' icon='download' onClick={downloadClick} />
                Download
              </span>
            </div>

          </div>
        </div>
      </Modal>
    </div>
  )
} 
export default PanelOptions