import React, { forwardRef, memo, useRef, useState, Ref, useEffect } from 'react'
import { ColumnActionsMode, ConstrainMode } from '@fluentui/react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import ArchiveCell from '@/features/messages/components/MessagesTable/components/ArchiveCell'
import EditCell from '@/features/messages/components/MessagesTable/components/EditCell'
import MessagesTable from '@/features/messages/components/MessagesTable/MessagesTable'
import CreateMessageModal, { CreateMessageModalHandle  } from '@/features/messages/components/CreateMessageModal'
import { useGetMessagesByAccountIdQuery } from '@/shared/api/services/messageService'
import { IMessageViewRecord } from '@/shared/types/swagger'
import { GeneralError } from '@/shared/components'
import { selectAccountId } from '@/features/auth/redux/authSelectors'
import { useGetBoxTokenQuery, useGetBoxClientRootFolderQuery } from '@/shared/api/services/boxServices'
import { BoxStateType } from '@/shared/types/box'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'

import './ActiveMessages.scss'

interface Props {
  hasActions?: boolean
  accountid?: string
}

const EditMessageModal = forwardRef(({ accountid }: Pick<Props, 'accountid'>, ref: Ref<CreateMessageModalHandle>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // Essential that MessagesTableActive also renders the EditMessageModal so that users can edit their message!
  const accountId = useSelector(selectAccountId)
  const contactsQueryState = useGetContactsByAccountIdQuery(accountid)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetBoxTokenQuery(accountid)
  const { data: rootFolderId } = useGetBoxClientRootFolderQuery(accountid)
  const [boxAccess, setBoxAccess] = useState<BoxStateType>({ token: '', folder: '' })
  useEffect(() => {
    if (!isLoading || !isUninitialized) {
      setBoxAccess({ token: data?.accessToken, folder: rootFolderId })
    }
  }, [isLoading, isUninitialized])
  return (
    <CreateMessageModal title='Edit Message' ref={ref} isOpen={isOpen} setIsOpen={setIsOpen} accountid={accountId} boxAccess={boxAccess} contacts={contactsQueryState} />
  )
})

EditMessageModal.displayName = 'EditMessageModal'

function ActiveMessages({ accountid, hasActions = true }: Props) {
  const ref = useRef<CreateMessageModalHandle>(null)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetMessagesByAccountIdQuery(accountid)

  const renderEditCellWithFunction = (d: IMessageViewRecord) => (
    <EditCell message={d} onClick={ref.current?.setValuesAndOpen} />
  )

  if (isError) {
    return <GeneralError title='Failed to load recent documents' onClick={refetch} />
  }

  if (isLoading || isUninitialized) {
    return <Skeleton height={200} width="100%" />
  }

  return (
    <>
      {(!data || (data.length === 0)) ?
        <p className='c-messages__no-messages'>You have no active messages</p>
        :
        <MessagesTable
          sectionProps={{
            title: 'Active Messages',
            className: 'c-active-table'
          }}
          headingTKey="activeMessages"
          extraColumns={hasActions ? {
            edit: {
              key: 'edit',
              name: '',
              fieldName: 'edit',
              minWidth: 50,
              onRender: renderEditCellWithFunction,
              columnActionsMode: ColumnActionsMode.disabled,
            },
            archive: {
              key: 'archive',
              name: '',
              fieldName: 'archive',
              minWidth: 50,
              onRender: ArchiveCell,
              columnActionsMode: ColumnActionsMode.disabled,
            }
          } : {}}
          data={data}
          constrainMode={ConstrainMode.unconstrained}
        />
      }
      <EditMessageModal ref={ref} accountid={accountid} />
    </>
  )
}

export default memo(ActiveMessages)
