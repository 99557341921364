import React from 'react'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import './ReturnToTotalPortfolio.scss'

export interface ReturnToTotalPortfolioProps {
  className?: string,
  portfolioId?: string,
  asOfDate?: string,
  id?: string,
  previewAccountId?: string,
}

const ReturnToTotalPortfolio: React.FC<ReturnToTotalPortfolioProps> = ({ className, portfolioId, asOfDate, id, previewAccountId }) => {
  const history = useHistory()

  const buttonClick = () => {
    if (previewAccountId) {
      history.push(`/preview/dashboard/${previewAccountId}/portfolio/${portfolioId}/${asOfDate}/${id}`)
      return
    }
    history.push(`/portfolio/${portfolioId}/${asOfDate}/${id}`)
  }

  return (
    <div className='button'>
      <DefaultButton className='ReturnToTotalPortfolio' onClick={buttonClick}>
        <FontAwesomeIcon className='icon' icon={['fas', 'arrow-left']} />
        <span className='button-label'>TOTAL PORTFOLIO</span>
      </DefaultButton>
    </div>
  )
}

export default ReturnToTotalPortfolio